import { getApiEndpoint } from '~/api/auth';
import type { Project, User } from '~/api/types.ts';
import { authFetch } from '~/api/utils';

export const getUser = async (): Promise<User> => {
  return await authFetch(getApiEndpoint() + 'user');
};

export const getUserProjects = async (): Promise<Project[]> => {
  return await authFetch(getApiEndpoint() + 'user/projects/', {
    method: 'GET',
  });
};

export const changeEmail = async ({ email }: { email: string }) => {
  return await authFetch(getApiEndpoint() + 'user/email/', {
    method: 'POST',
    body: {
      email,
    },
  });
};

import type { FetchError } from 'ofetch';
import { defineStore } from 'pinia';
import type { User } from '~/api/types';
import { getUser } from '~/api/user';
import { useAuthStore } from '~/stores/AuthStore';

export const useUserStore = defineStore('user', () => {
  const fetchUser = async () => {
    user.value = await getUser().catch((e: FetchError) => {
      if (e.status === 401) useAuthStore().logout();
      return user.value;
    });
  };

  const user: Ref<User | undefined> = ref();

  return {
    user,
    fetchUser,
  };
});

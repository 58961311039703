<script setup lang="ts">
interface ContextMenuCellProps {
  small?: boolean;
  large?: boolean;
}

const props = withDefaults(defineProps<ContextMenuCellProps>(), {
  small: false,
  large: false,
});
const cClass = computed(() => {
  const res = [];
  if (props.small) {
    res.push('context-menu-cell_sm');
  }
  if (props.large) {
    res.push('context-menu-cell_lg');
  }
  return res.join(' ');
});
</script>

<template>
  <button
    class="context-menu-cell"
    type="button"
    :class="cClass"
  >
    <slot name="iconLeft" />
    <slot>Content</slot>
  </button>
</template>

<style lang="scss">
.context-menu-cell {
  color: $foreground-contrast;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: none;
  text-align: left;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba($foreground-contrast, 0.04);
  }
}

.context-menu-cell_sm {
  padding: 6px;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.context-menu-cell_lg {
  padding: 12px;
  gap: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
</style>

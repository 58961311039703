import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack';
import { AuthStatus } from '~/stores/AuthStore';

export const authFetch = <Type>(request: NitroFetchRequest, opts: NitroFetchOptions<NitroFetchRequest> = {}) => {
  const authStore = useAuthStore();
  opts.headers = { ...opts.headers, Accept: 'application/json' };

  if (authStore.status == AuthStatus.AUTHORIZED) opts.headers = { ...opts.headers, Authorization: `Bearer ${authStore.token}` };

  const uid = useCookie('uid');
  if (uid) opts.headers = { ...opts.headers, Cookie: `uid=${uid.value}` };

  return $fetch<Type>(request, opts);
};

<script setup lang="ts">
import VModal from '~/components/common/VModal.vue';
import VRecoverPassword from '~/components/auth/VRecoverPassword.vue';
import VAuth from '~/components/auth/VAuth.vue';
import VButton from '~/components/VButton.vue';

interface AuthModalProps {
  isRegister: boolean;
}

const props = withDefaults(defineProps<AuthModalProps>(), {
  isRegister: false,
});
const showRegisterForm = ref<boolean>(props.isRegister);
const showRecoverForm = ref(false);
const formType = ref<'recover' | 'auth'>('auth');

defineEmits(['closedAuthModal', 'close']);
</script>

<template>
  <v-modal
    @close="
      showRegisterForm = false;
      $emit('closedAuthModal');
    "
  >
    <div v-if="formType === 'auth'">
      <v-auth
        :is-register="showRegisterForm.valueOf()"
        @close-auth="
          showRegisterForm = false;
          $emit('closedAuthModal');
        "
      />

      <v-button
        schema="text"
        type="button"
        style="width: 100%; margin-top: 8px"
        @click="formType = 'recover'"
      >
        Забыли пароль?
      </v-button>
    </div>

    <v-recover-password
      v-else
      @close="$emit('closedAuthModal')"
      @return="formType = 'auth'"
    />
  </v-modal>
</template>

<style lang="scss"></style>

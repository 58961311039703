export default defineNuxtPlugin(() => {
  const { user } = useUserStore();
  // Инициализация счетчика Mail.Ru
  const _tmr = window._tmr || (window._tmr = []);
  _tmr.push({
    id: '3558309',
    type: 'pageView',
    start: new Date().getTime(),
    pid: user?.id || undefined,
  });

  (function (d: Document, w: Window, id: string) {
    if (d.getElementById(id)) {
      return;
    }
    const ts = d.createElement('script');
    ts.type = 'text/javascript';
    ts.defer = true;
    ts.id = id;
    ts.src = 'https://top-fwz1.mail.ru/js/code.js';

    const f = function () {
      const s = d.getElementsByTagName('script')[0];
      s?.parentNode?.insertBefore(ts, s);
    };

    if (w.opera == '[object Opera]') {
      d.addEventListener('DOMContentLoaded', f, false);
    } else {
      f();
    }
  })(document, window, 'tmr-code');
});

<script lang="ts" setup>
import { submitResetCode, submitResetEmail, submitResetPassword } from '~/api/auth';
import { emailRegex } from '~/constants/regex';
import { useAuthStore } from '~/stores/AuthStore';
import { iconColors } from 'assets/js/utils';
import { Eye, EyeOff } from 'lucide-vue-next';

const emit = defineEmits(['close', 'return']);

const email = ref(useAuthStore().email || '');
const code = ref('');
const password = ref('');
const passwordRepeat = ref('');
const isPasswordMismatch = computed(
  () => password.value != passwordRepeat.value && !!password.value.length && !!passwordRepeat.value.length
);
const payload = ref<string | null>(null);
const error = ref<string | null>(null);
const step = ref<'email' | 'code' | 'new-password'>('email');
const loading = ref(false);
const showPassword = ref(false);

const resetForm = () => {
  step.value = 'email';
  error.value = null;
  payload.value = null;
  email.value = '';
  code.value = '';
  password.value = '';
  passwordRepeat.value = '';
};

const onEmailReset = async () => {
  loading.value = true;
  const response = await submitResetEmail(email.value).catch((e) => e.response._data);
  loading.value = false;

  if (response.error) {
    error.value = response.error!;
    return;
  }

  error.value = null;
  payload.value = response.payload;
  step.value = 'code';
};

const onCodeSubmit = async () => {
  if (!payload.value) return resetForm();

  const integerCode = Number(code.value.replace('-', ''));

  loading.value = true;
  const response = await submitResetCode(integerCode, payload.value).catch((e) => e.response._data);
  loading.value = false;

  if (response.error) {
    error.value = response.error!;
    return;
  }

  error.value = null;
  payload.value = response.payload;
  step.value = 'new-password';
};

const onPasswordSubmit = async () => {
  if (!payload.value) return resetForm();

  loading.value = true;
  const response = await submitResetPassword(password.value, payload.value).catch((e) => e.response._data);
  loading.value = false;

  if (response.error) {
    error.value = response.error!;
    return;
  }

  error.value = null;
  await useAuthStore().loginByToken(response.token!);
  emit('close');
  navigateTo('/profile');
};
</script>

<template>
  <div
    v-if="step === 'email'"
    class="recover-form"
  >
    <h3>Восстановление пароля</h3>
    <p>Введите свою почту, и мы отправим вам код для восстановления пароля</p>
    <v-input
      v-model="email"
      :error="!!error"
      placeholder="почта"
    />
    <span
      v-if="error"
      class="recover-form__error"
      >{{ error }}</span
    >
    <v-button
      medium
      :loading="loading"
      :disabled="!emailRegex.test(email)"
      @click="onEmailReset"
    >
      Отправить код
    </v-button>

    <v-button
      schema="text"
      @click="emit('return')"
      >Назад</v-button
    >
  </div>
  <div
    v-if="step === 'code'"
    class="recover-form"
  >
    <h3>Введите код подтверждения</h3>
    <p>Мы отправили код на вашу почту. Если сообщение не пришло, проверьте папку “Спам”.</p>
    <v-input
      v-model="code"
      :error="!!error"
      placeholder="000-000"
      mask="###-###"
    />
    <span
      v-if="error"
      class="recover-form__error"
      >{{ error }}</span
    >
    <v-button
      medium
      :loading="loading"
      :disabled="code.length != 7"
      @click="onCodeSubmit"
    >
      Подтвердить
    </v-button>
  </div>
  <div
    v-if="step === 'new-password'"
    class="recover-form"
  >
    <h3>Введите новый пароль</h3>
    <p>Придумайте новый пароль и подтвердите его ниже</p>
    <v-input
      v-model="password"
      :error="!!error"
      placeholder="новый пароль"
      :type="showPassword ? 'text' : 'password'"
    >
      <template #iconRight>
        <Eye
          v-if="showPassword"
          :color="iconColors.primary"
          :size="20"
          class="cursor-pointer"
          @click="showPassword = !showPassword"
        />
        <EyeOff
          v-else
          :color="iconColors.primary"
          :size="20"
          class="cursor-pointer"
          @click="showPassword = !showPassword"
        />
      </template>
    </v-input>
    <v-input
      v-model="passwordRepeat"
      :error="isPasswordMismatch"
      placeholder="повторите пароль"
      :type="showPassword ? 'text' : 'password'"
      style="margin-top: -12px"
    >
      <template #iconRight>
        <Eye
          v-if="showPassword"
          :color="iconColors.primary"
          :size="20"
          class="cursor-pointer"
          @click="showPassword = !showPassword"
        />
        <EyeOff
          v-else
          :color="iconColors.primary"
          :size="20"
          class="cursor-pointer"
          @click="showPassword = !showPassword"
        />
      </template>
    </v-input>
    <span
      v-if="error"
      class="recover-form__error"
      >{{ error }}</span
    >
    <span
      v-show="isPasswordMismatch"
      class="recover-form__error"
    >
      Пароли не совпадают
    </span>
    <v-button
      medium
      :loading="loading"
      :disabled="password != passwordRepeat"
      @click="onPasswordSubmit"
    >
      Сохранить пароль
    </v-button>
  </div>
</template>

<style lang="scss" scoped>
.recover-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;

  * {
    align-self: stretch;
  }

  h3 {
    margin: -24px 0 0 0;
  }

  p,
  h3 {
    padding: 0 40px;
  }

  p {
    font-size: 14px;
  }

  .recover-form__error {
    color: $text-status-destructive;
    font-size: 14px;
    margin-top: -20px;
    text-align: start;
    padding-left: 8px;
  }

  > *:last-child {
    margin-bottom: -24px;
  }
}
</style>

export const getApiEndpoint = () => useRuntimeConfig().public.apiEndpoint;

export type AuthResponse = {
  token: string;
};
export const login = async (email: string, password: string): Promise<AuthResponse> => {
  return await $fetch(getApiEndpoint() + 'login', {
    method: 'POST',
    body: {
      email,
      password,
    },
  });
};

export const loginByYandex = async (token: string): Promise<AuthResponse> => {
  return await $fetch(getApiEndpoint() + 'login_yandex', {
    method: 'POST',
    body: { token },
  });
};
export const loginByVK = async (payload: string): Promise<AuthResponse> => {
  return await $fetch(getApiEndpoint() + 'login_vk', {
    method: 'POST',
    body: { payload },
  });
};

export const register = async (name: string, email: string, password: string, password_confirmation: string): Promise<AuthResponse> => {
  return await $fetch(getApiEndpoint() + 'register', {
    method: 'POST',
    body: {
      name,
      email,
      password,
      password_confirmation,
    },
  });
};

type ResetResponse =
  | {
      result: 'error';
      error: string;
      payload: null;
    }
  | {
      result: 'success';
      error: null;
      payload: string;
    };

export const submitResetEmail = async (email: string) => {
  return await $fetch<ResetResponse>(getApiEndpoint() + 'password/reset', {
    method: 'POST',
    body: { email },
  });
};

export const submitResetCode = async (code: number, payload: string) => {
  return await $fetch<ResetResponse>(getApiEndpoint() + 'password/check', {
    method: 'POST',
    body: { code, payload },
  });
};

export const submitResetPassword = async (password: string, payload: string) => {
  return await $fetch<ResetResponse & { token: string | null }>(getApiEndpoint() + 'password/confirm', {
    method: 'POST',
    body: { password, password_confirmation: password, payload },
  });
};

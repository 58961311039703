import { default as _91entity_93SVvAV3KENdMeta } from "/app/src/pages/[entity].vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as _91id_93VS9sDAIO5LMeta } from "/app/src/pages/projects/[id].vue?macro=true";
import { default as _91slug_93SKy0faSzi6Meta } from "/app/src/pages/topics/[slug].vue?macro=true";
import { default as indexuosGcRMc9GMeta } from "/app/src/pages/topics/index.vue?macro=true";
import { default as vkL6SyM0WULbMeta } from "/app/src/pages/vk.vue?macro=true";
import { default as yandexXhMtitAb9sMeta } from "/app/src/pages/yandex.vue?macro=true";
export default [
  {
    name: "entity",
    path: "/:entity()",
    meta: _91entity_93SVvAV3KENdMeta || {},
    component: () => import("/app/src/pages/[entity].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    meta: _91id_93VS9sDAIO5LMeta || {},
    component: () => import("/app/src/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: "topics-slug",
    path: "/topics/:slug()",
    component: () => import("/app/src/pages/topics/[slug].vue").then(m => m.default || m)
  },
  {
    name: "topics",
    path: "/topics",
    component: () => import("/app/src/pages/topics/index.vue").then(m => m.default || m)
  },
  {
    name: "vk",
    path: "/vk",
    component: () => import("/app/src/pages/vk.vue").then(m => m.default || m)
  },
  {
    name: "yandex",
    path: "/yandex",
    component: () => import("/app/src/pages/yandex.vue").then(m => m.default || m)
  }
]
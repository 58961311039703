import type { EntityType } from '~/api/types';

type SeoMeta = {
  title: string;
  description: string;
  keywords?: string;
};

type CardMeta = {
  title: string;
  description?: string;
};

type BlockMeta = {
  title: string;
};

export type EntityMeta = {
  name: string;
  description: string;
  link: string;
  seo: SeoMeta;
  numberOfPages: number;
  lastProjectsBlock: BlockMeta;
  similiarProjectsBlock: BlockMeta;
  createProjectBlock: BlockMeta & {
    inputPlaceholder: string;
  };
  otherProjectCard: CardMeta;
  downloadCard: CardMeta;
  rateCard: CardMeta;
  isGeneratingCard: CardMeta;
};

export const entities: Record<EntityType, EntityMeta> = {
  project: {
    name: 'Проект',
    description: '10-20 страниц',
    numberOfPages: 20,
    link: '/',
    seo: {
      title: 'Генератор проектов с помощью нейронной сети',
      description:
        'Генерация школьных, курсовых, дипломных работ и индивидуальных проектов с помощью нейронной сети. Цели, задачи, содержание из 10 разделов уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания проекта, нейросеть для создания проекта, нейросеть пишет проект, написать проект с помощью нейросети, нейросеть для написания проектов, нейросеть написать проект, нейросеть придумывает цели',
    },
    createProjectBlock: {
      title: '<span style="color: $foreground-theme">Индивидуальный проект</span> с&nbsp;помощью нейросети',
      inputPlaceholder: 'Введите тему проекта',
    },
    lastProjectsBlock: {
      title: 'Последние проекты',
    },
    otherProjectCard: {
      title: 'Нужен другой проект?',
      description: 'Создай проект на любую тему за 60 секунд',
    },
    similiarProjectsBlock: {
      title: 'Похожие проекты',
    },
    downloadCard: {
      title: 'Ваш проект готов',
      description: 'Скачайте его в выбранном формате',
    },
    rateCard: {
      title: 'Оцените проект',
      description: 'Это поможет нам стать лучше',
    },
    isGeneratingCard: {
      title: 'Проект генерируется…',
    },
  },
  referat: {
    name: 'Реферат',
    description: '10-15 страниц',
    link: '/referat',
    numberOfPages: 15,
    seo: {
      title: 'Нейросеть для реферата',
      description:
        'Генератор рефератов помощью нейронной сети. Цели, задачи, содержание из 10 разделов 20 страниц уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания реферата, нейросеть для создания реферата, нейросеть пишет реферат, написать реферат с помощью нейросети, нейросеть для написания рефератов, нейросеть написать реферат',
    },
    lastProjectsBlock: {
      title: 'Последние рефераты',
    },
    similiarProjectsBlock: {
      title: 'Похожие рефераты',
    },
    createProjectBlock: {
      title: 'Сгенерируй <span style="color: $foreground-theme">реферат</span> с&nbsp;помощью нейросети',
      inputPlaceholder: 'Введите тему реферата',
    },
    otherProjectCard: {
      title: 'Нужен другой реферат?',
      description: 'Создай реферат на любую тему за 60 секунд',
    },
    downloadCard: {
      title: 'Ваш реферат готов',
      description: 'Скачайте его в выбранном формате',
    },
    rateCard: {
      title: 'Оцените реферат',
      description: 'Это поможет нам стать лучше',
    },
    isGeneratingCard: {
      title: 'Реферат генерируется…',
    },
  },
  'course-work': {
    name: 'Курсовая',
    description: '20-25 страниц',
    link: '/course-work',
    numberOfPages: 20,
    seo: {
      title: 'Нейросеть для курсовой работы',
      description:
        'Генератор курсовых работ помощью нейронной сети. Цели, задачи, содержание из 10 разделов уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для курсовой, нейросеть пишет курсовую, написать курсовую с помощью нейросети, нейросеть для написания курсовой, нейросеть написать курсовой, нейросеть придумывает курсовую',
    },
    lastProjectsBlock: {
      title: 'Последние курсовые',
    },
    similiarProjectsBlock: {
      title: 'Похожие курсовые',
    },
    createProjectBlock: {
      title: 'Создай <span style="color: $foreground-theme">курсовой проект</span> с&nbsp;помощью нейросети',
      inputPlaceholder: 'Введите тему курсовой',
    },
    otherProjectCard: {
      title: 'Нужна другая курсовая?',
      description: 'Создай курсовую работу на любую тему за 60 секунд',
    },
    downloadCard: {
      title: 'Ваша курсовая готова',
      description: 'Скачайте её в выбранном формате',
    },
    rateCard: {
      title: 'Оцените курсовую',
      description: 'Это поможет нам стать лучше',
    },
    isGeneratingCard: {
      title: 'Курсовая генерируется…',
    },
  },
  report: {
    name: 'Доклад',
    description: '10-15 страниц',
    link: '/report',
    numberOfPages: 15,
    seo: {
      title: 'Нейросеть для доклада',
      description:
        'Генератор докладов с помощью нейронной сети. Цели, задачи, содержание из 10 разделов, 10 страниц уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания доклада, нейросеть для создания доклада, нейросеть пишет доклад, написать доклад с помощью нейросети, нейросеть для написания докладов, нейросеть написать доклад',
    },
    lastProjectsBlock: {
      title: 'Последние доклады',
    },
    similiarProjectsBlock: {
      title: 'Похожие доклады',
    },
    createProjectBlock: {
      title: 'Создай <span style="color: $foreground-theme">доклад с презентацией</span> с&nbsp;помощью нейросети',
      inputPlaceholder: 'Введите тему доклада',
    },
    otherProjectCard: {
      title: 'Нужен другой доклад?',
      description: 'Создай доклад на любую тему за 60 секунд',
    },
    downloadCard: {
      title: 'Ваш доклад готов',
      description: 'Скачайте его в выбранном формате',
    },
    rateCard: {
      title: 'Оцените доклад',
      description: 'Это поможет нам стать лучше',
    },
    isGeneratingCard: {
      title: 'Доклад генерируется…',
    },
  },
  'final-essay': {
    name: 'Сочинение',
    description: '300 слов',
    link: '/final-essay',
    numberOfPages: 1,
    seo: {
      title: 'Нейросеть для итогового сочинения',
      description:
        'Итоговое сочинение с помощью нейронной сети. Содержание из 300 слов уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания итогового сочинения, нейросеть для создания итогового сочинения, нейросеть пишет итоговое сочинение, написать итоговое сочинение с помощью нейросети, нейросеть для написания итогового сочинения, нейросеть написать итоговое сочинение',
    },
    lastProjectsBlock: {
      title: 'Последние сочинения',
    },
    similiarProjectsBlock: {
      title: 'Похожие сочинения',
    },
    createProjectBlock: {
      title: 'Создай <span style="color: $new-fr-theme">итоговое сочинение</span> с&nbsp;помощью нейросети',
      inputPlaceholder: 'Введите тему сочинения',
    },
    otherProjectCard: {
      title: 'Нужно другое сочинение?',
      description: 'Создай сочинение на любую тему за 60 секунд',
    },
    downloadCard: {
      title: 'Сочинение готово',
      description: 'Скачайте его в выбранном формате',
    },
    rateCard: {
      title: 'Оцените сочинение',
      description: 'Это поможет нам стать лучше',
    },
    isGeneratingCard: {
      title: 'Сочинение генерируется…',
    },
  },
  text: {
    name: 'Текст',
    description: '15-20 страниц',
    link: '/text',
    numberOfPages: 20,
    seo: {
      title: 'Нейросеть для текста',
      description:
        'Текст с помощью нейронной сети. Содержание из 10 разделов 20 страниц уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания текста, нейросеть для создания текста, нейросеть пишет текст, написать текст с помощью нейросети, нейросеть для написания текстов, нейросеть написать текст',
    },
    lastProjectsBlock: {
      title: 'Последние тексты',
    },
    similiarProjectsBlock: {
      title: 'Похожие тексты',
    },
    createProjectBlock: {
      title: 'Создай <span style="color: $foreground-theme">уникальный текст</span> с&nbsp;помощью нейросети',
      inputPlaceholder: 'Введите тему текста',
    },
    otherProjectCard: {
      title: 'Нужен другой текст?',
      description: 'Создай текст на любую тему за 60 секунд',
    },
    downloadCard: {
      title: 'Ваш текст готов',
      description: 'Скачайте его в выбранном формате',
    },
    rateCard: {
      title: 'Оцените текст',
      description: 'Это поможет нам стать лучше',
    },
    isGeneratingCard: {
      title: 'Текст генерируется…',
    },
  },
};

import { defineStore } from 'pinia';

const DEFAULT_BOTTOM_OFFSET = 16;
const DEFAULT_RIGHT_OFFSET = 16;
export const useJivoStore = defineStore('jivo', () => {
  const isHidden = ref(false);
  const bottomOffset = ref(DEFAULT_BOTTOM_OFFSET);
  const rightOffset = ref(DEFAULT_RIGHT_OFFSET);

  const resetLayout = () => {
    bottomOffset.value = DEFAULT_BOTTOM_OFFSET;
    rightOffset.value = DEFAULT_RIGHT_OFFSET;
    isHidden.value = false;
  };

  const openChat = () => {
    if (window.jivo_api) {
      window.jivo_api.open();
    }
  };

  return {
    bottomOffset,
    rightOffset,
    isHidden,
    resetLayout,
    openChat,
  };
});

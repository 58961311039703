import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import * as Sentry from '@sentry/vue';
import type { User } from '~/api/types';
import { getUser } from '~/api/user';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const env = useRuntimeConfig();

  Sentry.init({
    app: [vueApp],
    dsn: env.public.sentry.dsn,
    environment: env.public.environment,
    debug: false,
    integrations: [
      Sentry.browserTracingIntegration({
        tracingOrigins: [/^https:\/\/begemot\.ai\/api/],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Browser Tracing
    tracePropagationTargets: [/^https:\/\/begemot\.ai\/api/],
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.2,
    // Callback Before Send
    beforeSend: async (event, hint) => {
      if (event.exception) {
        console.error(`[Exception handled by Sentry]: (${hint.originalException})`, {
          event,
          hint,
        });

        if (JSON.stringify(hint.originalException).includes('@webzlodimir_vue-bottom-sheet')) return null;
      }

      const user: User | undefined = useUserStore().user || (await getUser());

      event.user = user
        ? {
            id: user.id,
            username: user.name,
            email: user.email,
          }
        : undefined;

      // Continue sending to Sentry
      return event;
    },
    // denyUrls: [/localhost/i],
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    })
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });

  return {
    provide: {
      sentrySetContext: (n: string, context: { [key: string]: unknown } | null) => Sentry.setContext(n, context),
      sentrySetUser: (user: Sentry.User | null) => Sentry.setUser(user),
      sentrySetTag: (tagName: string, value: string | number | bigint | boolean | symbol | null | undefined) =>
        Sentry.setTag(tagName, value),
      sentryAddBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
    },
  };
});

import dayjs from 'dayjs';

// Black Friday
export const blackFridayStartDate = '2024-11-11T00:00:00Z';
export const blackFridayEndDate = '2024-11-15T23:59:59Z';

export const isBlackFridayNow = computed(() => {
  const now = dayjs();
  const startDate = dayjs(blackFridayStartDate);
  const endDate = dayjs(blackFridayEndDate);

  return now.isAfter(startDate) && now.isBefore(endDate);
});

import { authFetch } from '~/api/utils';
import { getApiEndpoint } from '~/api/auth';
import type { AnalyticEvent } from '~/api/types';

export const sendEvents = (events: AnalyticEvent[]): Promise<Response> => {
  return authFetch(getApiEndpoint() + 'logEvent', {
    method: 'POST',
    body: {
      events,
    },
  });
};

import { getEntityFromPath } from '~/middleware/entityRouting';

export default defineNuxtRouteMiddleware((to) => {
  const analyticsStore = useAnalyticsStore();

  const routeName = to.name?.toString();
  const isClient = import.meta.client; // True when evaluated on the client side.

  if (!routeName) return;

  const entity = to.path === '/' ? 'project' : getEntityFromPath(to.path); // project entity lives on index route

  if (isClient && !['yandex', 'vk'].includes(routeName)) {
    analyticsStore.openPage(entity || routeName);
  }
});

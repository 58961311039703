import { entities } from '~/enitityHelper';
import type { EntityType } from '~/api/types';

export const getEntityFromPath = (path: string): EntityType | undefined => {
  const pathName = path.slice(1); // remove slash char

  if (pathName in entities) return pathName as EntityType;
  else return undefined;
};

export default defineNuxtRouteMiddleware((to) => {
  const entity = getEntityFromPath(to.path);

  if (entity === 'project') return navigateTo('/', { redirectCode: 301 }); // project entity lives on index route
  if (entity) return;

  return navigateTo(`/`, { redirectCode: 301 }); // redirect to index if entity doesn't exist
});
